import { defineStore } from 'pinia'
import axios from 'axios'
import type { ICategory } from '../types/model'

export const useCategoryCodeStore = defineStore('caregoryCodeStore', {
  state: () => ({
    categoryList: [] as ICategory[]
  }),
  actions: {
    async fetchCategoryList () {
      try {
        const config = useRuntimeConfig()
        const AUTH_BASE_URL: string = config.public.API_HOST

        const response = await axios(AUTH_BASE_URL + '/category/v1/all')
        const categoryResponse = response.data
        if (categoryResponse.code === 0) {
          const categoryList: ICategory[] = categoryResponse.data.categoryList
          this.setCategoryList([...categoryList])
        } else {
          this.setCategoryList([])
        }
      } catch (error: any) {
        this.setCategoryList([])
      }
    },
    setCategoryList (catgegoryList: ICategory[]) {
      this.categoryList = catgegoryList
    }
  },
  getters: {
    list (): ICategory[] {
      return this.categoryList
    }
  }
})
